<template>
   <div class="header">
      <div v-if="loginStatusStore.loggedIn" class="logo">
            <router-link to="/home">
               <img class="logo-image" src="../assets/img/logo.png" v-tooltip="'Designer Skincare Products by Glover Manor.'">
            </router-link>
      </div>
      <div v-else class="logo">
            <router-link to="/">
               <img class="logo-image" src="../assets/img/logo.png" v-tooltip="'Designer Skincare Products by Glover Manor.'">
            </router-link>
      </div>

      <div v-if="loginStatusStore.loggedIn" class="menu-container">
         <div class="menu">
            <router-link v-if="loginStatusStore.isAdmin" to="/admin/products">
               <Button class="p-button-rounded p-button-primary menu-button" v-tooltip="'Platform Administration'">
                  Admin<i class="fas fa-jedi"></i>
               </Button>
            </router-link>

            <router-link to="/cart">
               <Button class="p-button-rounded p-button-primary menu-button" v-tooltip="cartTooltip">
                  <Badge :value="cartCount" severity="success" class="mr-2"></Badge>
                  Cart<i class="fas fa-cart-shopping"></i>
               </Button>
            </router-link>

            <router-link to="/orders">
               <Button class="p-button-rounded p-button-primary menu-button" v-tooltip="'Review Your Order History'">
                  Order History<i class="fas fa-file-circle-check"></i>
               </Button>
            </router-link>

            <router-link to="/recipes">
               <Button class="p-button-rounded p-button-primary menu-button" v-tooltip="'Review and Reorder Your Recipes'">
                  Recipes <i class="fas fa-flask"></i>
               </Button>
            </router-link>

            <router-link to="/settings">
               <Button class="p-button-rounded p-button-primary menu-button" v-tooltip="'Update Your Profile'">
                  Settings <i class="fas fa-cog"></i>
               </Button>
            </router-link>

            <router-link to="/logout?type=logout">
               <Button class="p-button-rounded p-button-primary menu-button" @mouseover="logoutToolTip" v-tooltip="logoutToolTipMessage">
                  Logout <i class="fas fa-right-from-bracket"></i>
               </Button>
            </router-link>

            <div v-if="avatar" class="profile">
               <div class="name">{{user.displayName}}</div>
               <router-link to="/settings">
                  <img :src="avatar" v-tooltip="'Click to Update Your Account Details.'">
               </router-link>
            </div>
         </div>
      </div>
      <div v-else class="menu-container">
         <div class="menu">
           <router-link to="/contact" id="contact" :key ='$route.params.id || $route.params'>
              <Button class="p-button-rounded p-button-primary menu-button">
                 Contact Us <i class="fa-solid fa-at"></i>
              </Button>
           </router-link>

           <router-link to="/register" id="register" :key ='$route.params.id || $route.params'>
              <Button class="p-button-rounded p-button-primary menu-button">
                 Create an Account <i class="fa-solid fa-circle-user"></i>
              </Button>
           </router-link>

           <router-link to="/login" id="login" :key ='$route.params.id || $route.params'>
              <Button class="p-button-rounded p-button-primary menu-button">
                 Sign into Your Account <i class="fas fa-right-to-bracket"></i>
              </Button>
           </router-link>
         </div>
      </div>
   </div>
</template>

<script setup>
   import { ref, onMounted, watchEffect } from 'vue'
   import { getAuth } from "firebase/auth";
   import { getDownloadURL, getStorage, ref as storageRef } from "firebase/storage";
   import { userProfileStore } from '@/stores/profile';
   import { loginStatusStore } from '@/stores/login.js'
   import { useRouter, useRoute } from 'vue-router';
   import Button from 'primevue/button';
   import Badge from 'primevue/badge';

   const storage = getStorage();
   const profile = userProfileStore();
   const router = useRouter();
   const route = useRoute();
   const auth = getAuth();

   const avatar = ref();
   let user = ref ({});
   let cartCount = 69000;
   let cartTooltip= "You have " + cartCount +" items in your cart."
   let logoutToolTipMessage = ref("");

   const refresh = function (state)
   {
      if (state)
      {
         user.value = profile.read();

         if (user.value && user.value['avatar'])
         {
            avatar.value = user.value.avatar + "&ts=" + user.value.id;
         }
         else
         {
            profile.add ("avatar", avatar.value);
         }
      }
   }

   onMounted(() =>
   {
      var path = "/images/avatar.png";
      var avatarStorageRef = storageRef(storage, path)

      //
      // Just in case....
      //
      if (router.currentRoute.value.path == "/logout" ||
          router.currentRoute.value.path == "/login")
      {
         loginStatusStore.loggedIn = false;
         loginStatusStore.isAdmin = false;
         return;
      }

      getDownloadURL (avatarStorageRef, path).then ((url) =>
      {
         avatar.value = url + "&ts=" + user.value.id;

         profile.load (true);
         refresh (true);
         watchEffect(() => refresh (loginStatusStore.refresh));
      });
   })

   //
   //
   //
   const logoutToolTip = function ()
   {
      const messages = ["It's not logout, its login again soon.",
                        "Sad to see you go, have an awesome day!",
                        "What kind of soap does a dolphin use? An all porpoise cleaner.",
                        "I used to be addicted to soap. I’m clean now.",
                        "What do you call pig shampoo? Hogwash.",
                        "When you leave here, you get away clean.",
                       ];
      var i = Math.floor(Math.random() * messages.length);
      logoutToolTipMessage.value = messages[i];
   }

   //
   // This does some magic that ensure that the user is not null if I am logged in, even on refresh.
   //
   async function asyncHandler (auth_user)
   {
      if (auth_user === null)
      {
         if (route.path != "/logout")
         {
            router.push ("/logout?type=timeout");
         }
         return;
      }

      //
      // The user is logged in.
      //
      user.value = auth_user;

      loginStatusStore.loggedIn = true;
      loginStatusStore.isAdmin = profile.isAdmin();

      auth_user.getIdTokenResult().then((IDTokenResult) =>
      {
         auth_user.getIdToken().then ((new_token) =>
         {
            //
            // This will either give us back our old token, or provide a new one.
            // Either way, save it as we use the profile.token for REST calls.
            //
            profile.reset_token (new_token);

            const now = Date.now();
            const authTime = IDTokenResult.claims.auth_time * 1000;
            const sessionDuration = 1000 * 60 * 60;
            const millisecondsUntilExpiration = sessionDuration - (now - authTime);

            loginStatusStore.sessionTimeout = now + sessionDuration;
            setTimeout( function() {auth.signOut(); }, millisecondsUntilExpiration);
         });
      });
   }
   auth.onAuthStateChanged (function (auth_user) {asyncHandler (auth_user)});
</script>

//
//
//  https://css-tricks.com/snippets/css/a-guide-to-flexbox/
//
//
<style scoped>
   .header
   {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 100px;
      flex-direction: row;
      background: #0b0a24;
      padding: 0px;
      border-bottom: 2px solid var(--header-border-color);
   }

   .logo
   {
      display: inline-block;
      width: 30%;
      align-items: center;
   }
   .logo img
   {
      margin: auto;
      height: 95px;
      vertical-align: middle;
   }

   .logo-image { height: 70px !important; }
   .logo-image img {height: auto;}

   .menu-container
   {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      flex-grow: 2;
   }

   .menu
   {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      align-items: center;
      padding-top: 2px;
      gap: 5px;
   }

   .profile
   {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 5px;
   }
   .profile img
   {
      height: 50px;
      width: 50px;
      margin-top: 2px;
      margin-right: 5px;
      border-radius: 25px;
      border: 2px solid var(--text-color);
   }

   .name
   {
      color: var(--text-color);
      text-align: center;
      font-weight: bold;
      margin-right: 10px;
   }
</style>
